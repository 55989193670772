import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BasicVideo = makeShortcode("BasicVideo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "conflict-with-your-teenager-establish-ground-rules"
    }}>{`Conflict with your teenager? Establish ground rules`}</h1>
    <p>{`Discuss some rules about conflict management with your teenager when you are both feeling calm. Both of you should agree to stick to these rules and to apologise when they are not observed.`}</p>
    <h3 {...{
      "id": "here-are-some-suggestions"
    }}>{`Here are some suggestions:`}</h3>
    <h4 {...{
      "id": "1-avoid-personal-attacks-and-all-hurtful-statements"
    }}>{`1. Avoid personal attacks and all hurtful statements.`}</h4>
    <p>{`This includes name-calling, blaming, shaming, swearing, and shouting. Also be
wary of sarcasm or hostile remarks. Making the other person feel horrible
reduces your chance of resolving the conflict or improving the situation.
Here's an example of how a mum's reaction to her son's behaviour can escalate
conflict:`}</p>
    <BasicVideo url="https://player.vimeo.com/video/575138539" mdxType="BasicVideo" />
    <h4 {...{
      "id": "2-express-feelings-appropriately"
    }}>{`2. Express feelings appropriately.`}</h4>
    <p>{`During a conflict, you can have very strong feelings. You may be angry, hurt,
enraged, sad, hopeful, or despairing. Careful and respectful expression of
these feelings helps to resolve conflicts. You do not need to hide your
feelings or pretend you don’t have any; just learn to express them clearly in
a non-destructive manner. Be aware of the tone of your voice. Try not to let
your emotions influence your tone of voice. The most neutral tone you can
maintain is best.`}</p>
    <p>{`Watch how the same mum expresses her feelings more respectfully and
constructively:`}</p>
    <BasicVideo url="https://player.vimeo.com/video/575138469" mdxType="BasicVideo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      